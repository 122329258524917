import React from "react";
import "../../../styles/KeyBlock.css";

interface Props {
  iconComponent: JSX.Element;
  title: string;
  description?: string;
}

function KeyBlock(props: Props) {
  return (
    <div>
      <div className="KeyBlock__title-container">
        <h4 className="KeyBlock__title">{props.title}</h4>
        {props.iconComponent}
      </div>
      {props.description && (
        <p className="KeyBlock__description">{props.description}</p>
      )}
    </div>
  );
}

export default KeyBlock;
