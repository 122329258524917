import React from "react";
import "../../../styles/KeyIcons.css";

interface Props {
  iconModifier?: string;
}

export function StandardSkill({ iconModifier }: Props) {
  return (
    <div className={`KeyIcon KeyIcon--standard KeyIcon--${iconModifier}`}>
      <p className="KeyIcon__text">Vertical Row</p>
    </div>
  );
}

export function MilestoneSkill({ iconModifier }: Props) {
  return (
    <div className={`KeyIcon KeyIcon--milestone KeyIcon--${iconModifier}`}>
      <p className="KeyIcon__text">Vertical Row</p>
    </div>
  );
}
