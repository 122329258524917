import React from "react";
import ButtonGroup from "../base/ButtonGroup";
import { MobileIcon, DesktopIcon } from "../../icons";

interface Props {
  selectedButton: "mobile" | "desktop";
  handleClick: (value: "mobile" | "desktop") => void;
}

function DeviceButtonGroup({ selectedButton, handleClick }: Props) {
  return (
    <ButtonGroup
      buttonOne={{
        id: "mobile",
        text: "mobile",
        icon: MobileIcon
      }}
      buttonTwo={{
        id: "desktop",
        text: "desktop",
        icon: DesktopIcon
      }}
      handleClick={handleClick as (value: string) => void}
      selectedButton={selectedButton}
      disabledButtons={[false, false]}
    />
  );
}

export default DeviceButtonGroup;
