import React from "react";
import DonateButton from "../DonateButton";

function Donate() {
  return (
    <React.Fragment>
      <h2>Contribute</h2>
      <p>
        You can donate to Cali Skills to support the infrastructure
        costs and the future development of the application.
      </p>
      <DonateButton location="info sheet" />
    </React.Fragment>
  );
}

export default Donate;
