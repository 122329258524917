import React from "react";
import { OutboundLink } from "react-ga";
import styles from "../../styles/AdditionalResources.module.css";

function AdditionalResources() {
  return (
    <React.Fragment>
      <h2>Additional resources</h2>
      <ul>
        <li className={styles.AdditionalResources__listItem}>
          <OutboundLink
            to="https://www.youtube.com/user/FitnessFAQs"
            target="_blank"
            eventLabel="Direct to FitnessFAQs from side sheet"
          >
            FitnessFAQs
          </OutboundLink>
        </li>
        <li className={styles.AdditionalResources__listItem}>
          {" "}
          <OutboundLink
            to="https://www.youtube.com/channel/UCZIIRX8rkNjVpP-oLMHpeDw"
            eventLabel="Direct to CaliMove from side sheet"
            target="_blank"
          >
            CaliMovement
          </OutboundLink>
        </li>
        <li className={styles.AdditionalResources__listItem}>
          {" "}
          <OutboundLink
            to="https://www.reddit.com/r/bodyweightfitness/"
            target="_blank"
            eventLabel="Direct to Reddit bwf from side sheet"
          >
            /r/bodyweightfitness
          </OutboundLink>
        </li>
        <li className={styles.AdditionalResources__listItem}>
          {" "}
          <OutboundLink
            to="https://stevenlow.org/tag/overcoming-gravity-2nd-edition/"
            target="_blank"
            eventLabel="Direct to Overcoming Gravity"
          >
            Overcoming Gravity
          </OutboundLink>
        </li>
      </ul>
    </React.Fragment>
  );
}

export default AdditionalResources;
