import React, { useState } from "react";
import { OutboundLink } from "react-ga";
import SideSheet from "../base/SideSheet";
import { version } from "../../../package.json";
import DeviceButtonGroup from "./DeviceButtonGroup";
import { useWindowSize, useEffectOnce } from "react-use";
import Key from "./Key/Key";
import AdditionalResources from "./AdditionalResources";
import Donate from "./Donate";
import AcknowledgementsAndDisclaimers from "./AcknowledgementsAndDisclaimers";
import PikePushupImage from "../images/PikePushupImage";
import { isSafari } from "../../helpers/isSafari";
import { Link } from "gatsby";

function InfoSheet() {
  const { width } = useWindowSize();
  const [safariState, setSafariState] = useState(false);
  const device = width < 900 ? "mobile" : "desktop";
  const [selectedButton, selectButton] = useState<"mobile" | "desktop">(device);

  useEffectOnce(() => {
    setSafariState(isSafari());
  });

  return (
    <SideSheet headingImage={<PikePushupImage alt="Man doing calisthenics" />}>
      <h2>Using Cali Skills</h2>
      <h3>A note about Cali Skills</h3>
      <p>
        There is no app on the App or Play Store. Cali Skills is designed to be
        used on most devices, on most browsers with no download required.
      </p>
      {safariState && (
        <p>
          <OutboundLink
            eventLabel="Open A2HS Safari video"
            target="_blank"
            to="https://calisthenics-skills-assets.s3.us-east-1.amazonaws.com/iphone-add-to-home-screen.mp4"
          >
            On Safari? Learn how you can add Cali Skills to your home
            screen
          </OutboundLink>
        </p>
      )}
      <p>What kind of device are you using?</p>
      <DeviceButtonGroup
        selectedButton={selectedButton}
        handleClick={selectButton}
      />
      <Key selectedButton={selectedButton} />
      <AdditionalResources />
      <Donate />
      <AcknowledgementsAndDisclaimers />
      <p>
        <Link to="/release-notes/">version: {version}</Link>
      </p>
    </SideSheet>
  );
}

export default InfoSheet;
