import React from "react";
import KeyBlock from "./KeyBlock";
import { StandardSkill, MilestoneSkill } from "./KeyIcons";

interface Props {
  selectedButton: "mobile" | "desktop";
}

const lockedDescription =
  "This skill cannot be completed until the preceding skills in the trees have also been completed.";
const availableDescription = "This skill is ready to be marked as completed.";
const milestoneSkillDescription =
  "A milestone skill is regarded as a significant achievement in the calisthenics community.";
const milestoneCompletedSkillDescription =
  "This vibrant skill should act as a reminder that you've achieved something incredible.";
const mobileFocusedDescription =
  "To learn more about a skill, press and hold the skill to display the movement description and a video.";
const desktopFocusedDescription =
  "This indicates that you can complete a skill by pressing the 'enter' key. You can also navigate the trees via the 'tab' key.";
const hoveredDescription =
  "You can hover over a skill to view a description and a video.";
const mobileCompletedDescription =
  "To complete a skill, tap on an available/focused skill. This will increase your total skill count.";
const desktopCompletedDescription =
  "To complete a skill, click on an available skill. This will increase your total skill count.";

function Key({ selectedButton }: Props) {
  const activeDescription =
    selectedButton === "mobile"
      ? mobileFocusedDescription
      : desktopFocusedDescription;

  const completedDescription =
    selectedButton === "mobile"
      ? mobileCompletedDescription
      : desktopCompletedDescription;

  return (
    <React.Fragment>
      <h3>Key</h3>
      <KeyBlock
        title="Standard Skill Available"
        iconComponent={<StandardSkill />}
        description={availableDescription}
      />
      <KeyBlock
        title="Standard Skill Completed"
        iconComponent={<StandardSkill iconModifier="standard-completed" />}
        description={completedDescription}
      />
      <KeyBlock
        title="Milestone Skill Available"
        iconComponent={<MilestoneSkill />}
        description={milestoneSkillDescription}
      />
      <KeyBlock
        title="Milestone Skill Completed"
        iconComponent={<MilestoneSkill iconModifier="milestone-completed" />}
        description={milestoneCompletedSkillDescription}
      />
      <KeyBlock
        title="Locked"
        iconComponent={<StandardSkill iconModifier="locked" />}
        description={lockedDescription}
      />
      <KeyBlock
        title="Focused"
        iconComponent={<StandardSkill iconModifier="focused" />}
        description={activeDescription}
      />
      {selectedButton === "desktop" && (
        <KeyBlock
          title="Hovered"
          iconComponent={<StandardSkill iconModifier="focused" />}
          description={hoveredDescription}
        />
      )}
    </React.Fragment>
  );
}

export default Key;
