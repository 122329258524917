import React from "react";
import { OutboundLink } from "react-ga";
import { Link } from "gatsby";

function AcknowledgementsAndDisclaimers() {
  return (
    <React.Fragment>
      <h2>Acknowledgements and disclaimers</h2>
      <p>
        I'm not a fitness professional, so please consult a doctor or
        professional before programming your workouts based on the information
        provided by Cali Skills.
      </p>
      <p>
        Photo by{" "}
        <OutboundLink
          to="https://www.instagram.com/thibautvandame/"
          target="_blank"
          eventLabel="Direct to @thibautvandame from side sheet"
        >
          Thibaut Vandame
        </OutboundLink>
      </p>
      <p>
        If you've encountered any bugs, or have any feature requests, we'd love
        to <Link to="/contact-us/">hear from you</Link>
      </p>
    </React.Fragment>
  );
}

export default AcknowledgementsAndDisclaimers;
